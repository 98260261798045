.navbar__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 68px;
  background: #000000;
}

.navbar__header-logo {
  height: 22px;
  padding: 0 20px;
}
