.home {
  padding-bottom: 30px;
}

.home__header-icon {
  position: absolute;
  top: 0px;
  right: 100px;
  opacity: 0.6;
  height: 125px;
}

.home__search {
  margin: 50px 0 0;
  text-align: center;
}

.home__courses-section {
  padding-top: 25px;
}

.home__tiles-title {
  padding: 0 15px;
}

.home__tiles-subtitle {
  font-size: 14px;
  color: #2F3234;
  padding: 0 15px;
  margin: 0 0 10px 0;
}

.home__tiles {
  display: grid;
  grid-gap: 30px;
  width: 100%;
}

.home__tiles--courses {
  grid-template-columns: repeat(auto-fit, minmax(260px,max-content));
}

.home__tiles--tutorials {
  grid-template-columns: repeat(auto-fit, minmax(156px,max-content));
}

.home__no-results {
  font-style: italic;
  padding-left: 15px;
}

/* tutorial box */
.tutorial-box {
  height: 136px;
  width: 156px;
  border-radius: 2px;
  background-color: #E6EBF0;
  margin: 15px;
}

.tutorial-box:hover {
  background: #CED8E2;
}

.tutorial-box__top-box {
  display: flex;
  align-items: center;
  height: 44px;
  width: 156px;
  border-radius: 2px;
  background-color: #00D3E8;
  position: relative;
}

.tutorial-box__image {
  padding: 0 11px;
}

.tutorial-box__title {
  font-size: 14px;
  color: #202020;
  margin: 14px 16px 0;
  line-height: 16px;
  height: 44px;
}

.tutorial-box__time {
  font-size: 10px;
  font-weight: bold;
  margin: 5px 16px;
  color: #202020;
}

/* course box */
.course-box {
  height: 258px;
  width: 266px;
  border-radius: 2px;
  background-color: #5F676E;
  margin: 15px;
}

.course-box__top-box {
  box-sizing: border-box;
  height: 160px;
  width: 266px;
  border-radius: 2px 2px 0 0; 
  position: relative;
  background: #E6EBF0;
  padding: 15px;
}

.course-box__top-box:hover {
  background: #CED8E2;
}

.course-box__image {
  background-size: contain;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.course-box__title {
  font-size: 16px;
  color: white;
  margin: 10px 20px 0;
  height: 16px;
  line-height: 22px;
}

.course-box__subtitle {
  font-size: 12px;
  color: white;
  margin: 5px 20px 0;
  height: 18px;
  line-height: 18px;
}

.course-box__time {
  font-size: 10px;
  margin: 20px;
  color: white;
  font-weight: bold;
}
