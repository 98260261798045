@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 18px;
  color: #2f3234;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapped {
  margin: 0 auto;
  width: 100%;
  max-width: 950px;
}

h1 {
  margin-bottom: 25px;
  line-height: 32px;
  font-size: 28px;
  font-weight: 600;
  color: #32436a;
}

h2 {
  padding-bottom: 15px;
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #2f3234;
}

h3 {
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
}

h4 {
  padding-bottom: 0;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: 14px;
  line-height: 18px;
}

a {
  color: #114ae6;
}
a:hover,
a:focus {
  color: #1890ff;
}

.ant-btn-primary {
  background-color: #114ae6;
  border-color: #114ae6;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #1890ff;
  border-color: #1890ff;
}
