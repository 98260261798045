.course__container {
  width: 100%;
  font-size: 16px;
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.course__form {
  margin-top: 20px;
}

.course__form label {
  display: none;
}

/* .course__form .ant-form-item {
  display: inline-block;
  vertical-align: baseline;
} */

.course__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 75px 20px 50px;
}

.course__outline {
  border-right: 1px solid #7a8288;
  margin-right: 60px;
  padding-right: 60px;
  min-width: 300px;
}

.course__outline h3:not(:first-of-type) {
  margin-top: 25px;
}

.course__outline-item {
  cursor: pointer;
}

.course__outline-item:hover,
.course__outline-item--active {
  color: #114ae6;
  font-weight: 700;
}

.course__verificationcode {
  font-size: 14px;
  margin-top: 10px;
}

.course__progress {
  font-size: 14px;
  margin-top: 10px;
}

#previewheader {
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

#previewheader h1 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top: 0px;
}

#iframeContainer {
  flex-grow: 1;
  min-height: 400px;
}

#iframeContainer iframe {
  border: 0px;
}
