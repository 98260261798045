.header {
  background: #e6ebf0;
}
.header__container {
  padding: 15px 15px 15px;
}
.header__container--large-margin {
  margin-top: 25px;
}
.header__back {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
}
.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header__description {
  margin-right: 25px;
}
.header__image {
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: right;
}
